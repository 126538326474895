<template>
  <home>
    <template v-slot:header>
      <a class="navbar-brand" href="#">
          <img src="../assets/vilavelha/logotipo.png" alt="">
      </a>
      <div class="navbar-nav ml-auto text-center ">
          <h4 class="secretaria">SECRETARIA MUNICIPAL <br> DE TECNOLOGIA E INOVAÇÃO</h4>
      </div>
    </template>
    <template v-slot:footer>
      <address>
          © Prefeitura de Vila Velha <br>
          SECRETARIA MUNICIPAL DE TECNOLOGIA E INOVAÇÃO<br>
          Endereço: Avenida Santa Leopoldina, 840 - Coqueiral de Itaparica, Vila Velha, ES - CEP: 29.102-375
      </address>
    </template>
  </home>
</template>


<script>
import Home from '../components/Home.vue'

export default {
  name: "VilaVelha",
  components: {
    Home
  }
};
</script>

<style>
#home.vilavelha {
  background: rgb(242, 242, 249);
  background-color: linear-gradient(to bottom, rgba(0, 191, 255, 0.2) 0%, rgba(92, 77, 66, 0.8) 100%);
  background-image: url(../assets/vilavelha/bg-vila-velha.jpg) !important;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

#home.vilavelha .secretaria {
  color: #fff;
  font-size: 1.1rem !important;
}

#home.vilavelha .btn-primary {
  background-color: #007bff !important;
}

#home.vilavelha .card-title {
  color: #1262B1 !important;
}

#home.vilavelha .itensselecionados li {
  color: #1262B1 !important;
}

#home.vilavelha .action-button {
  background: #1262B1 !important;
}

#home.vilavelha .fs-title {
  color: #1262B1 !important;
}

#home.vilavelha .purple-text {
  color: #1262B1 !important;
}

#home.vilavelha #progressbar .active {
  color: #1262B1 !important;
}

#home.vilavelha #progressbar li.active:before, #progressbar li.active:after {
  background: #1262B1 !important;
}

#home.vilavelha .progress-bar {
  background-color: #1262B1 !important;
}

#home.vilavelha #footer {
  background-color: #2A58C2 !important;
  color: #ffffff !important;
}

#home.vilavelha .lds-ellipsis div {
  background: #1262B1 !important;
}
</style>
