<template>
  <div id="home" :class="theme" v-cloak>
    <nav class="navbar navbar-expand-lg navbar-light bg-light static-top" v-if="exibirCabecalho">
        <div class="container">
          <slot name="header">
            <a class="navbar-brand" href="#">
                <img src="../assets/logo.png" alt="" height="55">
            </a>
          </slot>
        </div>
    </nav>
    <section>
      <div class="row justify-content-center main-content">
        <div
          class="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2"
        >
          <div class="card mt-3 mb-3">
            <div class="card-body">
              <h5 class="card-title">Novo agendamento</h5>
              <p>Siga os passos abaixo</p>
              <div class="text-center loading-container">
                  <div class="lds-ellipsis text-center" v-show="loading"><div></div><div></div><div></div><div></div></div>
              </div>
              <form @submit.prevent="submit" :class="{ 'was-validated': formValidated}">
                <!-- progressbar -->
                <ul id="progressbar">
                  <li :class="{ active: passo >= 0 }" id="servico">
                    <strong>Serviço</strong>
                  </li>
                  <li :class="{ active: passo >= 1 }" id="local">
                    <strong>Local</strong>
                  </li>
                  <li :class="{ active: passo >= 2 }" id="dados">
                    <strong>Dados</strong>
                  </li>
                  <li :class="{ active: passo >= 3 }" id="confirmacao">
                    <strong>Fim</strong>
                  </li>
                </ul>
                <!-- fieldsets -->
                <fieldset
                  class="passo"
                  v-if="passo === 0"
                  :class="{ itemativo: passo === 0 }"
                >
                  <div class="form-card">
                    <div class="row">
                      <div class="col-12">
                        <h2 class="fs-title">Informações do serviço:</h2>
                      </div>
                    </div>
                    <select
                      id="categoria"
                      class="form-control input-lg"
                      v-model="categoria"
                      @change="onChangeCategoria"
                      :disabled="loading"
                    >
                      <option :value="null" disabled>
                        - Escolha a categoria -
                      </option>
                      <option
                        :key="categoria.id"
                        :value="categoria"
                        v-for="categoria in categorias"
                      >
                        {{ categoria.nome }}
                      </option>
                    </select>
                    <br />
                    <select
                      id="servico"
                      class="form-control input-lg"
                      v-model="servico"
                      @change="onChangeServico"
                      :disabled="loading"
                    >
                      <option :value="null" disabled>- Escolha o serviço -</option>
                      <option v-for="servico in servicos" :key="servico.id" :value="servico">
                        {{ servico.nome }}
                      </option>
                    </select>
                    <div class="row mt-4">
                      <div class="col-12">
                        <div id="detalhes-servico" class="detalhes" v-if="servico">
                          <ul class="itensselecionados">
                            <li>
                              <i class="fa fa-tasks"></i>
                              Informações importantes do serviço:
                            </li>
                          </ul>
                          <div style="max-height: 200px; overflow-y: scroll">
                            <p
                              v-html="servico.descricao.replace(/\n/g, '<br>')"
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    name="next"
                    class="btn btn-primary pull-right action-button"
                    @click.prevent="proximoPasso"
                    :disabled="!isPassoHabilitado(passo + 1)"
                  >
                    Próximo
                    <i class="fa fa-arrow-right"></i>
                  </button>
                  <div class="text-center loading-container" style="margin-left: 100px">
                      <div class="lds-ellipsis agendar text-center" v-show="loading" ><div></div><div></div><div></div><div></div></div>
                  </div>
                </fieldset>
                <fieldset class="passo" :class="{ itemativo: passo === 1 }">
                  <div class="form-card" v-if="servico">
                    <div class="row">
                      <div class="col-12">
                        <ul class="itensselecionados">
                          <li>
                            <i class="fa fa-file-o"></i>
                            &nbsp;
                            <b>Serviço:</b>
                            {{ servico.nome }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <select
                      id="unidade"
                      class="form-control input-lg"
                      v-model="unidade"
                      @change="onChangeUnidade"
                      :disabled="loading"
                    >
                      <option :value="null" disabled>- Escolha o local -</option>
                      <option v-for="unidade in unidades" :key="unidade.id" :value="unidade">
                        {{ formataDescricaoUnidade(unidade) }}
                      </option>
                    </select>
                    <div class="row mt-4">
                      <div class="col-12">
                        <div id="detalhes-unidade" class="detalhes" v-if="unidade">
                          <ul class="itensselecionados">
                            <li>
                              <i class="fa fa-map-marker"></i>
                              Informações importantes do local:
                            </li>
                          </ul>
                          <p v-html="unidade.descricao.replace(/\n/g, '<br>')"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    name="next"
                    class="btn btn-primary pull-right action-button"
                    @click.prevent="proximoPasso($event)"
                    :disabled="!isPassoHabilitado(passo + 1)"
                  >
                    Próximo
                    <i class="fa fa-arrow-right"></i>
                  </button>
                  <button
                    type="button"
                    name="previous"
                    class="btn btn-secondary pull-left action-button-previous"
                    @click.prevent="passoAnterior($event)"
                  >
                    <i class="fa fa-arrow-left"></i>
                    Voltar
                  </button>
                  <div class="text-center loading-container">
                      <div class="lds-ellipsis agendar text-center" v-show="loading" ><div></div><div></div><div></div><div></div></div>
                  </div>
                </fieldset>
                <fieldset :class="{ passo: true, itemativo: passo === 2 }">
                  <div class="form-card" v-if="unidade && agenda">
                    <div class="row">
                      <div class="col-12">
                        <ul class="itensselecionados">
                          <li>
                            <i class="fa fa-file-o"></i>
                            &nbsp;
                            <b>Serviço:</b>
                            {{ servico.nome }}
                          </li>
                          <li>
                            <i class="fa fa-map-marker"></i>
                            &nbsp;
                            <b>Local:</b>
                            {{ unidade.nome }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <select
                            id="data"
                            class="form-control"
                            v-model="dados.data"
                            @change.prevent="changeData"
                            :disabled="loading"
                            required
                          >
                            <option :value="null" disabled>
                              - Escolha a data -
                            </option>
                            <option
                              v-for="dia in diasDisponiveis"
                              :value="dia"
                              :key="dia"
                            >
                              {{ dia.split("-").reverse().join("/") }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <select
                            id="horario"
                            class="form-control"
                            v-model="dados.horaDesejada"
                            v-if="agenda.horarioAutomatico === false"
                            :disabled="loading"
                          >
                            <option :value="null" disabled>
                              - Escolha a hora -
                            </option>
                            <option
                              v-for="hora in horariosDisponiveis"
                              :value="hora"
                              :key="hora"
                            >
                              {{ hora }}
                            </option>
                          </select>
                          <input
                            type="text"
                            class="form-control"
                            readonly
                            value="Automática"
                            :disabled="loading"
                            v-if="agenda.horarioAutomatico === true"
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="documento">Tipo de documento:</label>
                          <select
                            class="form-control"
                            id="documento"
                            v-model="tipoDocumento"
                            :disabled="loading"
                            required
                          >
                            <option
                              v-for="tipo in agenda.tiposDocumentos"
                              :value="tipo"
                              :key="tipo.id"
                            >
                              {{ tipo.nome }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class="form-group">
                          <label for="numero">Número do documento:</label>
                          <input
                            id="numero"
                            name="documento"
                            type="text"
                            class="form-control"
                            required
                            v-model="dados.documento"
                            @input="limpaDocumento"
                            :maxlength="tipoDocumento ? (tipoDocumento.tamanhoMaximo || 20) : 0"
                            :disabled="loading || !tipoDocumento"
                          />
                        </div>
                      </div>
                      <div class="col-md-12" v-if="agenda.configuracaoFormulario.nome !== 3">
                        <div :class="{ 'has-error': errorFields.nome, 'form-group': true }">
                          <label for="nome" class="control-label">
                            Nome<span v-if="agenda.configuracaoFormulario.nome === 2">  (opcional)</span>:
                          </label>
                          <input
                            id="nome"
                            name="nome"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errorFields.nome }"
                            maxlength="180"
                            v-model="dados.nome"
                            :required="agenda.configuracaoFormulario.nome === 1"
                            :disabled="loading"
                          />
                          <div
                            class="invalid-feedback"
                            v-text="errorFields.nome.join(' ')"
                            v-if="errorFields.nome"
                          ></div>
                        </div>
                      </div>
                      <div class="col-md-4" v-if="agenda.configuracaoFormulario.telefone !== 3">
                        <div :class="{ 'has-error': errorFields.telefone, 'form-group': true }">
                          <label for="telefone" class="control-label">
                            Telefone<span v-if="agenda.configuracaoFormulario.telefone === 2">  (opcional)</span>:
                          </label>
                          <input
                            id="telefone"
                            name="telefone"
                            type="text"
                            class="form-control telefone"
                            :class="{ 'is-invalid': errorFields.telefone }"
                            maxlength="15"
                            placeholder="(__) ____-_____"
                            @input="formataTelefone"
                            v-model="dados.telefone"
                            :required="agenda.configuracaoFormulario.telefone === 1"
                            :disabled="loading"
                          />
                          <div
                            class="invalid-feedback"
                            v-text="errorFields.telefone.join(' ')"
                            v-if="errorFields.telefone"
                          ></div>
                        </div>
                      </div>
                      <div class="col-md-8" v-if="agenda.configuracaoFormulario.email !== 3">
                        <div :class="{ 'has-error': errorFields.email, 'form-group': true }">
                          <label for="email" class="control-label">
                            E-mail<span v-if="agenda.configuracaoFormulario.email === 2"> (opcional)</span>:
                          </label>
                          <input
                            id="email"
                            name="email"
                            type="email"
                            class="form-control email"
                            :class="{ 'is-invalid': errorFields.email }"
                            maxlength="180"
                            v-model="dados.email"
                            :required="agenda.configuracaoFormulario.email === 1"
                            :disabled="loading"
                          />
                          <div
                            class="invalid-feedback"
                            v-text="errorFields.email.join(' ')"
                            v-if="errorFields.email"
                          ></div>
                        </div>
                      </div>
                      <div class="col-md-8" v-if="agenda.configuracaoFormulario.dataNascimento !== 3">
                        <div :class="{ 'has-error': errorFields.telefone, 'form-group': true }">
                          <label for="dataNascimento_dia" class="control-label">
                            Data nascimento<span v-if="agenda.configuracaoFormulario.dataNascimento === 2">  (opcional)</span>:
                          </label>
                          <div class="input-group">
                              <input
                                id="dataNascimento_dia"
                                type="number"
                                class="form-control"
                                :class="{ 'is-invalid': errorFields.dataNascimento }"
                                maxlength="2"
                                min="1"
                                max="31"
                                size="3"
                                v-model="dados.dataNascimento.dia"
                                placeholder="Dia"
                                data-field="dia"
                                @input="onChangeBirthDate"
                                :required="agenda.configuracaoFormulario.dataNascimento === 1"
                                :disabled="loading"
                              />
                              <input
                                id="dataNascimento_mes"
                                type="number"
                                class="form-control"
                                :class="{ 'is-invalid': errorFields.dataNascimento }"
                                maxlength="2"
                                min="1"
                                max="12"
                                size="3"
                                v-model="dados.dataNascimento.mes"
                                placeholder="Mês"
                                data-field="mes"
                                @input="onChangeBirthDate"
                                :required="agenda.configuracaoFormulario.dataNascimento === 1"
                                :disabled="loading"
                              />
                              <input
                                id="dataNascimento_ano"
                                type="number"
                                class="form-control"
                                :class="{ 'is-invalid': errorFields.dataNascimento }"
                                maxlength="4"
                                min="1900"
                                :max="maxBirthYear"
                                size="5"
                                v-model="dados.dataNascimento.ano"
                                placeholder="Ano"
                                data-field="ano"
                                @change="onChangeBirthDate"
                                :required="agenda.configuracaoFormulario.dataNascimento === 1"
                                :disabled="loading"
                              />
                          </div>
                          <div
                            class="invalid-feedback"
                            v-text="errorFields.dataNascimento.join(' ')"
                            v-if="errorFields.dataNascimento"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="agenda.configuracaoFormulario.endereco !== 3">
                      <div class="col-md-3">
                        <div :class="{ 'has-error': errorFields?.endereco?.cep, 'form-group': true }">
                          <label for="endereco_cep" class="control-label">
                            CEP<span v-if="agenda.configuracaoFormulario.endereco === 2"> (opcional)</span>:
                          </label>
                          <input
                            id="endereco_cep"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errorFields?.endereco?.cep }"
                            maxlength="8"
                            v-model="dados.endereco.cep"
                            :required="agenda.configuracaoFormulario.endereco === 1"
                            :disabled="loading"
                          />
                          <div
                            class="invalid-feedback"
                            v-text="errorFields.endereco.cep.join(' ')"
                            v-if="errorFields?.endereco?.cep"
                          ></div>
                        </div>
                      </div>
                      <div class="col-md-7">
                        <div :class="{ 'has-error': errorFields?.endereco?.logradouro, 'form-group': true }">
                          <label for="endereco_logradouro" class="control-label">
                            Logradouro<span v-if="agenda.configuracaoFormulario.endereco === 2"> (opcional)</span>:
                          </label>
                          <input
                            id="endereco_logradouro"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errorFields?.endereco?.logradouro }"
                            maxlength="180"
                            v-model="dados.endereco.logradouro"
                            :required="agenda.configuracaoFormulario.endereco === 1"
                            :disabled="loading"
                          />
                          <div
                            class="invalid-feedback"
                            v-text="errorFields.endereco.logradouro.join(' ')"
                            v-if="errorFields?.endereco?.logradouro"
                          ></div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div :class="{ 'has-error': errorFields?.endereco?.numero, 'form-group': true }">
                          <label for="endereco_numero" class="control-label">
                            Número<span v-if="agenda.configuracaoFormulario.endereco === 2"> (opcional)</span>:
                          </label>
                          <input
                            id="endereco_numero"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errorFields?.endereco?.numero }"
                            maxlength="10"
                            v-model="dados.endereco.numero"
                            :required="agenda.configuracaoFormulario.endereco === 1"
                            :disabled="loading"
                          />
                          <div
                            class="invalid-feedback"
                            v-text="errorFields.endereco.numero.join(' ')"
                            v-if="errorFields?.endereco?.numero"
                          ></div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div :class="{ 'has-error': errorFields?.endereco?.estado, 'form-group': true }">
                          <label for="endereco_estado" class="control-label">
                            UF<span v-if="agenda.configuracaoFormulario.endereco === 2"> (opcional)</span>:
                          </label>
                          <select
                            id="endereco_estado"
                            class="form-control"
                            :class="{ 'is-invalid': errorFields?.endereco?.estado }"
                            v-model="dados.endereco.estado"
                            :required="agenda.configuracaoFormulario.endereco === 1"
                            :disabled="loading">
                            <option value=""></option>
                            <option value="DF">DF</option>
                            <option value="AC">AC</option>
                            <option value="AL">AL</option>
                            <option value="AP">AP</option>
                            <option value="AM">AM</option>
                            <option value="BA">BA</option>
                            <option value="CE">CE</option>
                            <option value="ES">ES</option>
                            <option value="GO">GO</option>
                            <option value="MA">MA</option>
                            <option value="MT">MT</option>
                            <option value="MS">MS</option>
                            <option value="MG">MG</option>
                            <option value="PA">PA</option>
                            <option value="PB">PB</option>
                            <option value="PR">PR</option>
                            <option value="PE">PE</option>
                            <option value="PI">PI</option>
                            <option value="RJ">RJ</option>
                            <option value="RN">RN</option>
                            <option value="RS">RS</option>
                            <option value="RO">RO</option>
                            <option value="RR">RR</option>
                            <option value="SC">SC</option>
                            <option value="SP">SP</option>
                            <option value="SE">SE</option>
                            <option value="TO">TO</option>
                          </select>
                          <div
                            class="invalid-feedback"
                            v-text="errorFields.endereco.estado.join(' ')"
                            v-if="errorFields?.endereco?.estado"
                          ></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div :class="{ 'has-error': errorFields?.endereco?.cidade, 'form-group': true }">
                          <label for="endereco_cidade" class="control-label">
                            Cidade<span v-if="agenda.configuracaoFormulario.endereco === 2"> (opcional)</span>:
                          </label>
                          <input
                            id="endereco_cidade"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errorFields?.endereco?.cidade }"
                            maxlength="60"
                            v-model="dados.endereco.cidade"
                            :required="agenda.configuracaoFormulario.endereco === 1"
                            :disabled="loading"
                          />
                          <div
                            class="invalid-feedback"
                            v-text="errorFields.endereco.cidade.join(' ')"
                            v-if="errorFields?.endereco?.cidade"
                          ></div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div :class="{ 'has-error': errorFields?.endereco?.bairro, 'form-group': true }">
                          <label for="endereco_bairro" class="control-label">
                            Bairro<span v-if="agenda.configuracaoFormulario.endereco === 2"> (opcional)</span>:
                          </label>
                          <input
                            id="endereco_bairro"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errorFields?.endereco?.bairro }"
                            maxlength="60"
                            v-model="dados.endereco.bairro"
                            :required="agenda.configuracaoFormulario.endereco === 1"
                            :disabled="loading"
                          />
                          <div
                            class="invalid-feedback"
                            v-text="errorFields.endereco.bairro.join(' ')"
                            v-if="errorFields?.endereco?.bairro"
                          ></div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div :class="{ 'has-error': errorFields?.endereco?.complemento, 'form-group': true }">
                          <label for="endereco_complemento" class="control-label">
                            Complemento<span v-if="agenda.configuracaoFormulario.endereco === 2"> (opcional)</span>:
                          </label>
                          <input
                            id="endereco_complemento"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errorFields?.endereco?.complemento }"
                            maxlength="180"
                            v-model="dados.endereco.complemento"
                            :disabled="loading"
                          />
                          <div
                            class="invalid-feedback"
                            v-text="errorFields.endereco.complemento.join(' ')"
                            v-if="errorFields?.endereco?.complemento"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="agenda.campos && agenda.campos.length">
                      <div
                        :class="'col-xs-12 col-md-' + campo.largura"
                        v-for="(campo, index) in agenda.campos"
                        :key="campo.id"
                      >
                        <div class="checkbox" v-if="campo.tipo === 'checkbox'">
                          <label>
                            <input
                              type="checkbox"
                              v-model="dados.respostas[index].valor"
                              :required="campo.obrigatorio"
                              :disabled="loading"
                            />
                            {{ campo.nome }}
                          </label>
                        </div>
                        <div
                          class="form-group"
                          v-if="
                            ['text', 'email', 'date'].indexOf(campo.tipo) !== -1
                          "
                        >
                          <label v-text="campo.nome"></label>
                          <input
                            class="form-control"
                            :type="campo.tipo"
                            v-model="dados.respostas[index].valor"
                            :required="campo.obrigatorio"
                            :disabled="loading"
                          />
                        </div>
                        <div class="form-group" v-if="campo.tipo === 'select'">
                          <label v-text="campo.nome"></label>
                          <select
                            class="form-control"
                            v-model="dados.respostas[index].valor"
                            :disabled="loading"
                            :required="campo.obrigatorio"
                          >
                            <option value=""></option>
                            <option
                              :value="opcao"
                              :key="opcao"
                              v-text="opcao"
                              v-for="opcao in campo.opcoes"
                            ></option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="btn btn-primary pull-right action-button"
                    @click.prevent="submit"
                    :disabled="!isPassoHabilitado(passo + 1) || loading"
                  >
                    <i class="fa fa-calendar"></i>
                    Agendar
                  </button>
                  <button
                    type="button"
                    name="previous"
                    class="btn btn-secondary pull-left action-button-previous"
                    @click.prevent="passoAnterior($event)"
                  >
                    <i class="fa fa-arrow-left"></i>
                    Voltar
                  </button>
                  <div class="text-center loading-container">
                      <div class="lds-ellipsis agendar text-center" v-show="loading" ><div></div><div></div><div></div><div></div></div>
                  </div>
                </fieldset>
                <fieldset class="passo" :class="{ itemativo: passo === 3 }">
                  <div class="form-card">
                    <div class="success-checkmark">
                      <div class="check-icon">
                        <span class="icon-line line-tip"></span>
                        <span class="icon-line line-long"></span>
                        <div class="icon-circle"></div>
                        <div class="icon-fix"></div>
                      </div>
                    </div>
                    <h2 class="heading text-center">
                      <strong>Agendamento efetuado com sucesso</strong>
                    </h2>
                    <br />
                    <div class="row justify-content-center" v-if="novoAgendamento">
                      <div class="col-12 text-center">
                        <div class="alert alert-warning">
                          <p>
                            <strong>ATENÇÃO</strong>
                            <br>
                            Para cancelar este agendamento você deverá informar o seguinte código: <strong>{{ novoAgendamento.codigo }}</strong>
                          </p>
                        </div>
                        <p v-if="novoAgendamento.nome">
                          <strong>Nome</strong>: {{ novoAgendamento.nome }}
                        </p>
                        <p>
                          <strong>{{ novoAgendamento.tipoDocumento.nome }}</strong>: {{ novoAgendamento.documento }}
                        </p>
                        <p>
                          <strong>Data</strong>: {{ novoAgendamento.data.split("-").reverse().join("/") }} 
                          <strong>Hora</strong>: {{ novoAgendamento.horaInicio }}</p>
                        <p>
                          <strong>Serviço</strong>: {{ novoAgendamento.servico.nome }}
                        </p>
                        <p>
                          <strong>Local</strong>: {{ novoAgendamento.unidade.nome }}
                        </p>
                        <p>
                          <strong>Observação</strong>: 
                          <br>
                          <span style="display:block; max-height: 200px;overflow: auto; white-space: pre-wrap;">{{ novoAgendamento.informacaoImpressao }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <button
                        type="button"
                        name="previous"
                        class="btn btn-secondary action-button-previous"
                        @click.prevent="iniciar"
                      >
                        <i class="fa fa-tasks"></i>
                        Agendar outro serviço
                      </button>
                    </div>
                    <div class="col-6 text-right">
                      <button
                        type="button"
                        class="btn btn-primary action-button"
                        @click="imprimir(novoAgendamento, novoAgendamento.codigo)"
                      >
                        <i class="fa fa-calendar"></i>
                        Imprimir Agendamento
                      </button>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
          <form
            @submit.prevent="buscar"
            class="mt-3 mb-5"
            id="consultar-cancelar"
             v-if="exibirConsulta"
          >
            <div class="card consultar my-3">
              <div class="card-header font-weight-bold">
                <i class="fa fa-search"></i>
                Consultar agendamento
              </div>
              <div class="card-body">
                <div class="row pt-3">
                  <div class="col-md-7">
                    <div class="form-group">
                      <label for="busca-tipodocumento" class="sr-only"
                        >Escolha o documento</label
                      >
                      <select
                        id="busca-tipodocumento"
                        class="form-control input-lg"
                        v-model="tipoDocumentoBusca"
                        :disabled="loading"
                      >
                        <option value="" disabled>
                          Escolha o documento
                        </option>
                        <option
                          :key="tipo.id"
                          :value="tipo"
                          v-for="tipo in todosTiposDocumentos"
                        >
                          {{ tipo.nome }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="busca-documento" class="sr-only"
                        >Digite o número:</label
                      >
                      <div class="input-group mb-3">
                        <input
                          id="busca-documento"
                          type="text"
                          class="form-control"
                          placeholder="Digite seu documento"
                          aria-label="Digite seu documento"
                          v-model="busca"
                          :disabled="loading"
                        />
                        <div class="input-group-append">
                          <button
                            type="submit"
                            class="btn btn-primary"
                          >
                            <i class="fa fa-search"></i>
                            <span class="d-none d-md-inline">
                              Buscar
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <p>
                      <i class="fa fa-info"></i>
                      Para sua segurança e preservação dos dados pessoais é necessário
                      informar o código do agendamento para imprimir ou cancelar
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div id="modal-busca" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <table
                class="table table-striped table-hover"
                v-if="agendamentos.length"
              >
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Hora</th>
                    <th>Serviço</th>
                    <th>Unidade</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="agendamento in agendamentos" :key="agendamento.id">
                    <td>
                      {{ agendamento.data }}
                    </td>
                    <td>
                      {{ agendamento.horaInicio }}
                    </td>
                    <td>
                      {{ agendamento.servico.nome }}
                    </td>
                    <td>
                      {{ agendamento.unidade.nome }}
                    </td>
                    <td class="text-right text-nowrap">
                      <button
                        type="button"
                        class="btn btn-default"
                        title="Imprimir"
                        @click="imprimir(agendamento)"
                      >
                        <i class="fa fa-print"></i>
                      </button>

                      <button
                        type="button"
                        class="btn btn-danger"
                        title="Cancelar"
                        @click="cancelar(agendamento)"
                      >
                        <i class="fa fa-ban"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer id="footer" class="rodext" v-if="exibirRodape">
        <div class="container">
            <slot name="footer">
              <address>
                © EuAgendei.Online
              </address>
            </slot>
        </div>
    </footer>

    <button type="button" class="btn voltarTopo" value="Voltar ao topo">
        <span class="glyphicon glyphicon-chevron-up"></span>
    </button>
  </div>
</template>

<script>
import { apiUrl, theme, reCaptchaKey } from '../profile'
import $ from 'jquery'
import swal from 'sweetalert'
import 'sweetalert/dist/sweetalert.css'

const dataNascimentoVazia = {
  dia: null,
  mes: null,
  ano: null,
}

const enderecoVazio = {
  logradouro: null,
  bairro: null,
  numero: null,
  cep: null,
  cidade: null,
  estado: null,
  complemento: null,
}

const dadosVazio = {
  data: null,
  horaDesejada: null,
  nome: "",
  documento: "",
  tipoDocumento: null,
  servico: null,
  unidade: null,
  telefone: null,
  email: null,
  captcha: null,
  endereco: { ...enderecoVazio },
  dataNascimento: { ...dataNascimentoVazia },
  respostas: [],
}

export default {
  name: 'Home',
  props: {
    exibirCabecalho: {
      type: Boolean,
      default: true,
    },
    exibirConsulta: {
      type: Boolean,
      default: true,
    },
    exibirRodape: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      theme,
      showBusca: false,
      passos: 4,
      passo: 0,
      current: 1,
      categoria: null,
      servico: null,
      unidade: null,
      tipoDocumento: null,
      servicos: [],
      categorias: [],
      unidades: [],
      diasDisponiveis: [],
      agendamentos: [],
      errorFields: [],
      todosTiposDocumentos: [],
      horariosDisponiveis: [],
      agenda: null,
      novoAgendamento: null,
      dados: { ...dadosVazio },
      busca: "",
      tipoDocumentoBusca: null,
      loading: false,
      avisoLimite: null,
      formValidated: false
    };
  },
  computed: {
    maxBirthYear() {
      return (new Date()).getFullYear()
    },
    dataNascimentoSql() {
      if (!this.dados.dataNascimento.ano || !this.dados.dataNascimento.mes || !this.dados.dataNascimento.dia) {
        return null
      }
      return [
        this.dados.dataNascimento.ano,
        this.dados.dataNascimento.mes,
        this.dados.dataNascimento.dia
      ].join("-");
    }
  },
  methods: {
    goto: function (passo) {
      if (passo >= 0 && passo < this.passos && this.isPassoHabilitado(passo)) {
        this.passo = passo;
        $(window).scrollTop(0);
      }
    },

    setProgressBar: function (curStep) {
      var percent = parseFloat(100 / this.passos) * curStep;
      percent = percent.toFixed();
      $(".progress-bar").css("width", percent + "%");
    },

    iniciar: function () {
      this.passo = 0;
      this.categoria = null;
      this.servico = null;
      this.unidade = null;
      this.tipoDocumento = null;
      this.horariosDisponiveis = [];
      this.agenda = null;
      this.novoAgendamento = null;
      this.dados = { ...dadosVazio }
      this.formValidated = false
      $("#sucesso").html("");
    },

    proximoPasso: function () {
      this.setProgressBar(++this.current);
      this.goto(this.passo + 1);
    },

    passoAnterior: function () {
      this.setProgressBar(--this.current);
      this.goto(this.passo - 1);
    },

    isPassoHabilitado: function (passo) {
      if (passo > 2) {
        return this.diasDisponiveis.length;
      }
      if (passo > 1) {
        return !!this.unidade && this.diasDisponiveis.length;
      }
      if (passo > 0) {
        return !!this.servico;
      }
      return true;
    },

    limpaDocumento: function () {
      this.dados.documento = this.dados.documento.replace(/[^\d]+/g, '')
    },

    formataTelefone: function (e) {
      let index = 0
      let mascara = e.target.placeholder
      let numeros = this.dados.telefone.replace(/[^\d]+/g, '')
      if (numeros.length) {
        for (let i = 0; i < numeros.length; i++) {
          index = mascara.indexOf('_', i + 1)
          mascara = mascara.substring(0, index) + numeros.charAt(i) + mascara.substring(index + 1)
        }
        this.dados.telefone = mascara.substring(0, index + 1)
      } else {
        this.dados.telefone = ''
      }
    },

    onChangeCategoria: function () {
      this.servico = null;
      this.servicos = [];
      this.unidade = null;
      this.unidades = [];
      return this.loadServicos();
    },

    onChangeServico: function () {
      this.unidade = null;
      this.unidades = [];
      this.loadDiasDisponiveis();
      return this.loadUnidades();
    },

    onChangeUnidade: function () {
      this.loadDiasDisponiveis();
    },

    onChangeBirthDate(evt) {
      const field = evt.target.dataset.field
      const value = parseInt(evt.target.value)
      if (isNaN(value) || value === 0) {
        this.dados.dataNascimento[field] = null
        return
      }
      const min = parseInt(evt.target.min)
      if (value < min) {
        this.dados.dataNascimento[field] = min
        return
      }
      const max = parseInt(evt.target.max)
      if (value > max) {
        this.dados.dataNascimento[field] = max
        return
      }
    },

    loadCategorias: function () {
      var self = this;
      self.loading = true;

      return $.ajax({
        url: apiUrl + "/categorias",
        dataType: "json",
        success: function (response) {
          self.categorias = response;
        },
        complete: function () {
          self.loading = false;
        },
      });
    },

    loadServicos: function () {
      var self = this;

      if (!self.categoria) {
        return Promise.resolve();
      }

      self.loading = true;

      return $.ajax({
        url: apiUrl + "/categorias/" + self.categoria.id + "/servicos",
        dataType: "json",
        success: function (response) {
          self.servicos = response;
        },
        complete: function () {
          self.loading = false;
        },
      });
    },

    loadTiposDocumentos: function () {
      var self = this;

      $.ajax({
        url: apiUrl + "/tiposdocumentos",
        dataType: "json",
        success: function (response) {
          self.todosTiposDocumentos = response;
          if (self.todosTiposDocumentos.length) {
            self.tipoDocumentoBusca = self.todosTiposDocumentos[0];
          }
        },
      });
    },

    loadUnidades: function () {
      var self = this;

      if (!self.servico || !self.servico.id) {
        return;
      }

      self.loading = true;

      return $.ajax({
        url: apiUrl + "/servicos/" + self.servico.id + "/unidades/vagas",
        dataType: "json",
        success: function (response) {
          self.unidades = response;
        },
        complete: function () {
          self.loading = false;
        },
      });
    },

    loadDiasDisponiveis: function () {
      var self = this;
      self.loading = true;
      self.tiposDocumentos = [];
      self.agenda = null;
      self.diasDisponiveis = [];
      self.errorFields = {};
      self.dados.data = null;
      self.dados.respostas = [];

      if (self.unidade && self.unidade.id && self.servico && self.servico.id) {
        $.ajax({
          url:
            apiUrl +
            "/servicos/" +
            self.servico.id +
            "/unidades/" +
            self.unidade.id,
          dataType: "json",
          cache: false,
          success: function (config) {
            self.avisoLimite = config.avisoLimiteAtingido;
            self.diasDisponiveis = config.diasDisponiveis;
            self.agenda = config.agenda;
            if (self.agenda.campos) {
              for (let campo of self.agenda.campos) {
                self.dados.respostas.push({
                  valor: null,
                  campo: campo.id,
                });
              }
            }
          },
          complete: function () {
            self.loading = false;
            if (!self.diasDisponiveis.length) {
              var aviso;

              if (self.avisoLimite) {
                aviso = self.avisoLimite.replace(/\n/g, "<br>");
              } else {
                aviso =
                  "Infelizmente o limite máximo de agendamento para o serviço escolhido foi atingido ou o mesmo não está mais disponível";
              }

              swal({
                title: "Aviso",
                text: aviso,
                type: "info",
                html: true,
              });
            }
          },
        });
      }
    },

    loadHorariosDisponiveis: function () {
      var self = this;
      self.loading = true;

      if (
        self.unidade &&
        self.unidade.id &&
        self.servico &&
        self.servico.id &&
        self.dados.data
      ) {
        $.ajax({
          url:
            apiUrl +
            "/servicos/" +
            self.servico.id +
            "/unidades/" +
            self.unidade.id +
            "/horarios/" +
            self.dados.data,
          dataType: "json",
          cache: false,
          success: function (horarios) {
            self.horariosDisponiveis = horarios;
          },
          complete: function () {
            self.loading = false;
            // evita mostrar mensagem quando acabar de fazer um agendamento
            if (!self.novoAgendamento && !self.horariosDisponiveis.length) {
              swal({
                title: "Aviso",
                text:
                  "Infelizmente não há horário disponível para a data escolhida.",
                type: "info",
                html: true,
              });
            }
          },
        });
      }
    },

    loadRecaptchaToken() {
      return new Promise((resolve) => {
        window.grecaptcha.ready(async () => {
          const token = await window.grecaptcha.execute(reCaptchaKey, {action: 'novoAgendamento'});
          resolve(token);
      })})
    },

    changeData: function () {
      this.horariosDisponiveis = [];

      if (!this.agenda.horarioAutomatico) {
        this.loadHorariosDisponiveis();
      }
    },

    submit: function (el) {
      this.erros = {};
      if (!this.dados.data) {
        swal({
          title: "Erro",
          text: "Favor escolher o dia desejado.",
        });
        return;
      }
      if (!this.agenda.horarioAutomatico && !this.dados.horaDesejada) {
        swal({
          title: "Erro",
          text: "Favor escolher a hora desejada.",
        });
        return;
      }
      if (!this.tipoDocumento || !this.tipoDocumento.id) {
        swal({
          title: "Erro",
          text: "Favor escolher o tipo de documento.",
        });
        return;
      }
      if (!this.dados.documento) {
        swal({
          title: "Erro",
          text: "Favor preencher o número do documento.",
        });
        return;
      }

      if (this.agenda.horarioAutomatico) {
        this.verificaHorario(el);
      } else {
        this.confirmarHorario(el);
      }
    },

    async confirmarHorario () {
      var self = this;
      var dia = self.dados.data.split("-").reverse().join("/");
      this.dados.captcha = await this.loadRecaptchaToken();
      swal(
        {
          title: "Confirmar",
          text:
            "Deseja confirmar o agendamento para o dia <strong>" +
            dia +
            "</strong> às <strong>" +
            self.dados.horaDesejada +
            "</strong>?<br><br><strong>Local: " +
            self.unidade.nome +
            "</strong>",
          type: "info",
          html: true,
          showCancelButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Confirmar agendamento",
          closeOnConfirm: true,
        },
        function () {
          if (!self.loading) {
            $(".confirm").attr('disabled', 'disabled');
            self.agendar();
          }
        }
      );
    },

    prepareDados() {
      const data = {
        ...this.dados,
        servico: this.servico.id,
        unidade: this.unidade.id,
        tipoDocumento: this.tipoDocumento ? this.tipoDocumento.id : null,
        dataNascimento: this.dataNascimentoSql,
      }
      if (this.agenda.configuracaoFormulario.nome === 3) {
        delete data.nome
      }
      if (this.agenda.configuracaoFormulario.email === 3) {
        delete data.email
      }
      if (this.agenda.configuracaoFormulario.telefone === 3) {
        delete data.telefone
      }
      if (this.agenda.configuracaoFormulario.dataNascimento === 3) {
        delete data.dataNascimento
      }
      if (this.agenda.configuracaoFormulario.endereco === 3) {
        delete data.endereco
      }
      return data
    },

    verificaHorario: function () {
      var self = this

      const data = this.prepareDados()

      this.errorFields = {};
      this.formValidated = false;

      if (!this.loading) {
        this.loading = true;
        $.ajax({
          url: apiUrl + "/agendamentos/check",
          type: "post",
          data: JSON.stringify(data),
          dataType: "json",
          cache: false,
          success: function (response) {
            if (response.horaInicio) {
              self.dados.horaDesejada = response.horaInicio;

              var dia = response.data.split("-").reverse().join("/");
              swal(
                {
                  title: "Confirmar",
                  text:
                    "Deseja confirmar o agendamento para o dia <strong>" +
                    dia +
                    "</strong> às <strong>" +
                    response.horaInicio +
                    "</strong>?<br><br><strong>Local: " +
                    self.unidade.nome +
                    "</strong>",
                  type: "info",
                  html: true,
                  showCancelButton: true,
                  cancelButtonText: "Não",
                  confirmButtonText: "Confirmar agendamento",
                  closeOnConfirm: false,
                },
                function () {
                  if (!self.loading) {
                    $(".confirm").attr('disabled', 'disabled');
                    self.agendar();
                  }
                }
              );
            } else if (response.error) {
              self.errorFields = response.fields || [];
              swal("Erro", response.error, "error");
            }
          },
          error: function (response) {
            if (response.responseJSON && response.responseJSON.error) {
              self.errorFields = response.responseJSON.fields || [];
              swal("Erro", response.responseJSON.error, "error");
            } else {
              swal(
                "Erro",
                "Erro desconhecido. Favor verificar sua conexão ou tentar mais tarde.",
                "error"
              );
            }
          },
          complete: function () {
            self.loading = false;
            self.formValidated = true;
          },
        });
      }
    },

    agendar: function () {
      var self = this;

      const data = this.prepareDados()

      this.errorFields = {};

      if (this.agenda.campos) {
        for (let i = 0; i < self.agenda.campos.length; i++) {
          let campo = self.agenda.campos[i];
          let resposta = self.dados.respostas[i];
          if (campo.obrigatorio && !resposta) {
            swal("Atenção", "Favor preencher todos os campos.", "error");
            return;
          }
        }
      }

      this.loading = true;
      this.formValidated = false;

      $.ajax({
        url: apiUrl + "/agendamentos",
        type: "post",
        data: JSON.stringify(data),
        dataType: "json",
        cache: false,
        success: function (response) {
          if (response.horaInicio) {
            self.novoAgendamento = response;
            self.proximoPasso();
            $(".check-icon").hide();
            setTimeout(function () {
              $(".check-icon").show();
            }, 100);
            self.changeData();
          } else if (response.error) {
            self.errorFields = response.fields || [];
            swal("Erro", response.error, "error");
          }
        },
        error: function (response) {
          if (response.responseJSON && response.responseJSON.error) {
            self.errorFields = response.responseJSON.fields || [];
            swal("Erro", response.responseJSON.error, "error");
          } else {
            swal(
              "Erro",
              "Erro desconhecido. Favor verificar sua conexão ou tentar mais tarde.",
              "error"
            );
          }
        },
        complete: function () {
          self.loading = false;
          self.formValidated = true;
        },
      });
    },

    buscar: function (evitaAlerta) {
      var self = this;
      $.ajax({
        url: apiUrl + "/agendamentos",
        data: {
          documento: self.busca,
          tipoDocumento: self.tipoDocumentoBusca.id,
        },
        cache: false,
        dataType: "json",
        success: function (response) {
          self.agendamentos = (response || []).map(function (agendamento) {
            for (var j = 0; j < self.servicos.length; j++) {
              if (agendamento.servico_id === self.servicos[j].id) {
                agendamento.servico = self.servicos[j];
                break;
              }
            }
            agendamento.data = agendamento.data.split("-").reverse().join("/");

            return agendamento;
          });

          if (self.agendamentos.length) {
            $("#modal-busca").modal("show");
          } else {
            $("#modal-busca").modal("hide");
            if (evitaAlerta !== true) {
              swal({
                title: "Aviso",
                text: 'Nenhum agendamento encontrado para o documento informado.',
                type: "info",
              });
            }
          }
        },
        error: function (response) {
          if (response.responseJSON && response.responseJSON.error) {
            swal("Erro", response.responseJSON.error, "error");
          } else {
            swal(
              "Erro",
              "Erro desconhecido. Favor verificar sua conexão ou tentar mais tarde.",
              "error"
            );
          }
        },
      });
    },

    imprimir: function (agendamento, codigo) {
      if (!codigo) {
        codigo = (window.prompt('Favor informar o código do agendamento') || '').toUpperCase()
      }
      if (!codigo) {
        return
      }
      window.open("/#/print/" + agendamento.id + "?codigo=" + codigo + "&_=" + new Date().getTime())
    },

    cancelar: function (agendamento) {
      var self = this;
      swal(
        {
          title: "Deseja cancelar?",
          text:
            "Ao confirmar o seu agendamento será cancelado. Essa ação não poderá ser desfeita.",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Desejo cancelar o agendamento!",
          cancelButtonText: "Não",
          closeOnConfirm: false,
          closeOnCancel: true,
        },
        function (isConfirm) {
          if (isConfirm) {
            const codigo = (window.prompt('Favor informar o código do agendamento') || '').toUpperCase()
            if (!codigo) {
              return
            }
            $(".confirm").attr('disabled', 'disabled');
            $.ajax({
              url: apiUrl + "/agendamentos/" + agendamento.id,
              type: "DELETE",
              data: {
                codigo
              },
              success: function () {
                swal(
                  "Cancelado!",
                  "O seu agendamento foi cancelado com sucesso.",
                  "success"
                );
                self.buscar(true);
              },
              error: function (response) {
                if (response.responseJSON && response.responseJSON.error) {
                  swal("Erro", response.responseJSON.error, "error");
                } else {
                  swal(
                    "Erro",
                    "Erro desconhecido. Favor verificar sua conexão ou tentar mais tarde.",
                    "error"
                  );
                }
              },
            });
          }
        }
      );
    },

    formataDescricaoUnidade: function (unidade) {
      let formatado = "";

      if (unidade.vagasDisponiveis <= 0) {
        formatado = `${unidade.nome} (Indisponí­vel)`;
      } else if (unidade.vagasDisponiveis == 1) {
        formatado = `${unidade.nome} (${unidade.vagasDisponiveis} disponível)`;
      } else {
        formatado = `${unidade.nome} (${unidade.vagasDisponiveis} disponíveis)`;
      }

      return formatado;
    },
  },
  mounted: function () {
    this.setProgressBar(this.current);
  },
  beforeMount: function () {
    const categoriaInicial = 0;
    const servicoInicial = 0;
    const unidadeInicial = 0;

    this.loadCategorias()
      .then(() => {
        if (categoriaInicial) {
          this.categoria = this.categorias.find(
            (c) => c.id === categoriaInicial
          );
          if (this.categoria) {
            return this.onChangeCategoria();
          }
        }
      })
      .then(() => {
        if (servicoInicial) {
          this.servico = this.servicos.find((s) => s.id === servicoInicial);
          if (this.servico) {
            this.passo = 1;
            return this.onChangeServico();
          }
        }
      })
      .then(() => {
        if (unidadeInicial) {
          this.unidade = this.unidades.find((u) => u.id === unidadeInicial);
          if (this.unidade) {
            this.passo = 2;
            this.onChangeUnidade();
          }
        }
      });
    this.loadTiposDocumentos();
  },
};
</script>

<style scoped>
#home {
  padding-bottom: 170px;
  color: #333;
  background: rgb(242, 242, 249);
  background: linear-gradient(to bottom, rgba(0, 191, 255, 0.2) 0%, rgba(92, 77, 66, 0.8) 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  min-height: 100%;
  position: relative;
}


.card-title {
  color: #0454A8;
  font-weight: bold;
  font-size: 1.3rem;
}

.main-content {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.itensselecionados li {
  color: #0454A8;
  list-style-type: none !important;
  border-bottom: 1px solid #dedede;
}

.form-card {
  text-align: left
}

/*aqui*/

.action-button {
  min-width: 100px;
  background: #0454A8;
  font-weight: bold;
  color: white;
  border: 0 none;
  cursor: pointer;
  padding: 10px 10px;
  margin: 10px 0 10px 5px;
  float: right
}

.card {
  z-index: 0;
  border: none;
  position: relative
}

.fs-title {
  font-size: 25px;
  color: #0454A8;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left
}

.purple-text {
  color: #0454A8;
  font-weight: normal
}

.steps {
  font-size: 15px;
  color: gray;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: right
}

.fieldlabels {
  color: gray;
  text-align: left
}

label {
  margin-bottom: 2px;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: #999;
}

#progressbar .active {
  color: #0454A8;
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400
}

#progressbar #servico:before {
  font-family: FontAwesome;
  content: "\f016"
}

#progressbar #local:before {
  font-family: FontAwesome;
  content: "\f041"
}

#progressbar #dados:before {
  font-family: FontAwesome;
  content: "\f073"
}

#progressbar #confirmacao:before {
  font-family: FontAwesome;
  content: "\f00c"
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: gray;
  border-radius: 50%;
  margin: 0 auto 10px;
  padding: 2px
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: #999;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1
}

#progressbar li.active:before, #progressbar li.active:after {
  background: #0454A8
}

.progress {
  height: 20px
}

.progress-bar {
  background-color: #0454A8
}

.fit-image {
  width: 100%;
  object-fit: cover
}

.passo {
  display: none;
}

.passo.itemativo {
  display: block;
}

.conteudo {
  margin-bottom: 70px;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 5px;
  background-color: #0454A8;
  color: #ffffff;
}

.btn-primary {
  background-color: #0454A8;
}

.rodext {
  border-top: 1px solid #dae1e4;
  padding-top: 25px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to
/* .fade-leave-active em versões anteriores a 2.1.8 */

  {
  opacity: 0;
}

/**
 * Extracted from: SweetAlert
 * Modified by: Istiak Tridip
 */

.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
}

.success-checkmark .check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #0b4281;
}

.success-checkmark .check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}

.success-checkmark .check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}

.success-checkmark .check-icon::before, .success-checkmark .check-icon::after {
  content: "";
  height: 100px;
  position: absolute;
  transform: rotate(-45deg);
}

.success-checkmark .check-icon .icon-line {
  height: 5px;
  background-color: #0b4281;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}

.success-checkmark .check-icon .icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}

.success-checkmark .check-icon .icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}

.success-checkmark .check-icon .icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(76, 175, 80, 0.5);
}

.success-checkmark .check-icon .icon-fix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
}

.bg-light {
  background-color: rgba(248, 248, 248, 0.2) !important;
}

.card {
  background-color: rgba(255, 255, 255, 0.6)!important;
}

fieldset {
  background-color: transparent !important;
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

/********* smart + smart paisagem ******/

@media screen and (min-width: 120px) and (max-width: 767px) {
  .faixa-gen {
    padding-bottom: 12%
  }
  .nav_agendamento a {
    font-size: 20px;
  }
  .table {
    display: block;
    position: relative;
    width: 100%;
  }
  .table>thead>tr>th {
    padding: 3px 8px 2px 8px !important
  }
  .table thead {
    font-size: 14px
  }
  .table tbody tr {
    font-size: 14px
  }
  .table thead, .table tbody, .table th, .table td, .table tr {
    display: block;
  }
  .table td, .table th {
    height: 35px;
  }
  .table thead {
    float: left;
  }
  .table tbody {
    width: auto;
    position: relative;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }
  .table tbody tr {
    display: inline-block;
  }
}

.loading-container {
  height: 20px;
  }
  
  .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  }
  
  .lds-ellipsis.agendar {
  height: 0;
  }
  
  .lds-ellipsis div {
  position: absolute;
  top: 5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #0454A8;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
  }
  @keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
  }
  @keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
  

[v-cloak] { display:none; }
</style>
