import { createRouter, createWebHashHistory } from "vue-router"
import ThemeLoader from "./components/ThemeLoader.vue"
import Printer from "./components/Printer.vue"
import PageNotFound from "./components/PageNotFound.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: ThemeLoader,
  },
  {
    path: "/print/:id",
    name: "Printer",
    component: Printer,
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
