<template>
    <div>
        Página não encontrada
    </div>
</template>

<script>
export default {
    name: 'PageNotFound',
}
</script>
