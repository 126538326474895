
<template>
  <home>
  </home>
</template>

<script>
import Home from '../components/Home.vue'

export default {
  name: "Default",
  components: {
    Home
  }
};
</script>

<style scoped>
</style>
