<template>
  <home :exibirConsulta="false">
    <template v-slot:header>
      <a class="navbar-brand" href="#">
          <img src="../assets/araguaina/logo.png" alt="">
      </a>
    </template>
    <template v-slot:footer>
        <address>
            © Prefeitura Municipal de Araguaína <br>
            Endereço: Palácio Tancredo Rua 25 de Dezembro, 52, Centro - CEP 77.804-030, Araguaína-Tocantins
        </address>
    </template>
  </home>
</template>

<script>
import Home from '../components/Home.vue'

export default {
  name: "Araguaina",
  components: {
    Home
  }
};
</script>

<style>

/* $main-color: #999; */

#home.araguaina {
  background-image: url(../assets/araguaina/bg-araguaina.png) !important;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

#home.araguaina .card {
  background: linear-gradient(to bottom, rgb(232, 245, 253, 0.8) 0%, rgba(218, 229, 233, 0.6) 100%) !important;
}

#home.araguaina .navbar {
   background-color: #22407c !important;
}

#home.araguaina .btn-primary {
  background-color: #22407c !important;
}

#home.araguaina .card-title {
  color: #22407c !important;
}

#home.araguaina .itensselecionados li {
  color: #22407c !important;
}

#home.araguaina .action-button {
  background: #22407c !important;
}

#home.araguaina .fs-title {
  color: #22407c !important;
}

#home.araguaina .purple-text {
  color: #22407c !important;
}

#home.araguaina #progressbar .active {
  color: #22407c !important;
}

#home.araguaina #progressbar li.active:before, #progressbar li.active:after {
  background: #22407c !important;
}

#home.araguaina .progress-bar {
  background-color: #22407c !important;
}

#home.araguaina #footer {
  background-color: #22407c !important;
  color: #ffffff !important;
}

#home.araguaina .lds-ellipsis div {
  background: #22407c !important;
}
</style>
