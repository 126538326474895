<template>
  <home>
    <template v-slot:header>
      <a class="navbar-brand" href="#">
          <img src="../assets/sinemariana/logotipo.png" alt="">
      </a>
    </template>
    <template v-slot:footer>
      <address>
          SINE Mariana  <br>
          Avenida Getúlio Vargas S/N Centro (Centro de Convenções) <br>
          Mariana/MG | CEP 35420-015
      </address>
    </template>
  </home>
</template>


<script>
import Home from '../components/Home.vue'

export default {
  name: "SineMariana",
  components: {
    Home
  }
};
</script>

<style>
#home.sinemariana {
  background-color: #2957a4;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

#home.sinemariana .navbar, #home.sinemariana #footer {
  background-color: #ffffff !important;
  color: #2957a4;
}
</style>
