<template>
  <home>
    <template v-slot:header>
      <a class="navbar-brand" href="#">
          <img src="../assets/ftmsaude/logo.png" alt="">
      </a>
    </template>
    <template v-slot:footer>
      <address>
          Wall street empresarial, sala 623B<br>
          Av. Luís Viana, Paralela - Patamares<br>
          Salvador - BA, 41730-101
      </address>
    </template>
  </home>
</template>


<script>
import Home from '../components/Home.vue'

export default {
  name: "FtmSaude",
  components: {
    Home
  }
};
</script>

<style>
#home.ftmsaude {
  background-color: #ffffff;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(https://terramaesaude.org/wp-content/uploads/2023/01/FTM-coracoes.png);
}

#home.ftmsaude .navbar, {
  background-color: rgba(256,255,255,.15) !important;
  color: #fff;
  font-weight: bold;
  border: 0;
}
</style>
