<template>
  <ThemeComponent />
</template>

<script>
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { profile } from '../profile'

// se passar "profile.theme" dentro do try dá erro
const theme = profile.theme
let ThemeComponent
try {
  ThemeComponent = require(`../themes/${theme}.vue`).default
} catch (e) {
  ThemeComponent = require(`../themes/Default.vue`).default
}

export default {
  name: "ThemeLoader",
  components: {
    ThemeComponent
  }
};
</script>

<style scoped>
</style>
