<template>
  <router-view />
</template>

<script>
import { RouterView } from 'vue-router'

export default {
  name: 'App',
  components: {
    RouterView
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  font-family: poppins-light, Arial;
}

html, body, #app {
  height: 100%
}
</style>
