const theme = (process.env.VUE_APP_THEME || 'default')
const appProfiles = require('../package.json').appProfiles
const profile = appProfiles[theme];

const apiUrl = (process.env.VUE_APP_API_URL || profile.apiUrl)
const printLogo = require("./assets/" + profile.printLogo)
const printTitle = profile.printTitle
const reCaptchaKey = profile.reCaptchaKey

export {
    theme,
    profile,
    apiUrl,
    printLogo,
    printTitle,
    reCaptchaKey,
}
