<template>
    <div>
		<div v-if="!loading && !agendamento" class="container py-4">
			<div class="alert alert-danger text-center">
				<h1>Agendamento não encontrado</h1>
				Certifique-se que o <strong>código</strong> informado está correto e tente novamente.
			</div>
		</div>
		<div v-if="agendamento">
			<header>
				<div class="header-report">
					<div class="row">
						<div class="col">
							<img class="logo" :src="logo">
						</div>
						<div class="col">
							<h2>{{ title }}</h2>
							<p>
								<span v-text="agendamento.unidade.nome"></span>
							</p>
							<p>
								<span v-text="agendamento.servico.nome"></span>
							</p>
							<p class="show-on-400">
								<small v-text="agendamento.id"></small>
							</p>
						</div>
						<div class="col show-on-400">
							<a href="javascript:void()" onclick="window.print()" class="hide-on-print">
								<i class="glyphicon glyphicon-print"></i>
								Imprimir
							</a>
						</div>
					</div>
				</div>
			</header>
			<section>
				<h1>Agendamento</h1>
				<div class="alert alert-warning text-center" v-if="agendamento.codigo">
					<strong>ATENÇÃO</strong>
					<br>
					Para cancelar este agendamento você deverá informar o seguinte código: <strong>{{ agendamento.codigo.toUpperCase() }}</strong>
				</div>
				<table class="table table-bordered">
					<tr v-if="agendamento.nome">
						<th>
							Nome
						</th>
						<td>
							<span v-text="agendamento.nome"></span>
						</td>
					</tr>
					<tr>
						<th>
							<label v-text="agendamento.tipoDocumento.nome"></label>
						</th>
						<td>
							<span v-text="agendamento.documento"></span>
						</td>
					</tr>
					<tr>
						<th>
							Data
						</th>
						<td>
							<span v-text="agendamento.data.split('-').reverse().join('/')"></span>
						</td>
					</tr>
					<tr>
						<th>
							Hora
						</th>
						<td>
							<span v-text="agendamento.horaInicio"></span>
						</td>
					</tr>
					<tr>
						<th>
							Serviço
						</th>
						<td>
							<span v-text="agendamento.servico.nome"></span>
						</td>
					</tr>
					<tr>
						<th>
							Local
						</th>
						<td>
							<span v-text="agendamento.unidade.nome"></span>
						</td>
					</tr>
				</table>
				<table class="table table-bordered">
					<tr>
						<th>
							Observações
						</th>
					</tr>
					<tr>
						<td>
							<p v-text="agendamento.informacaoImpressao" style="white-space: pre-wrap"></p>
						</td>
					</tr>
				</table>
			</section>
		</div>
	</div>
</template>


<script>
import { apiUrl, printLogo, printTitle } from '../profile'

export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
			loading: true,
            agendamento: null,
            logo: printLogo,
            title: printTitle,
        }
    },
    methods: {
        async loadAgendamento(id) {
			this.loading = true
			const codigo = this.$route.query.codigo
            const resp = await fetch(`${apiUrl}/agendamentos/${id}?codigo=${codigo}`)
			this.loading = false
            if (resp.ok) {
				this.agendamento = await resp.json()
				setTimeout(() => {
					window.print()
					window.close()
				}, 800);
			}
        }
    },
    beforeMount() {
        this.loadAgendamento(this.id)
    }
}
</script>


<style>
@page {
	width: 100%;
	margin: 0;
	padding: 0;
}

@media all and (max-width: 399px) {
	.hide-on-400 { display: block; }
	.show-on-400 { display: none; }
}

@media all and (min-width: 400px) {
	.hide-on-400 { display: none; }
	.show-on-400 { display: block; }
}

@media all {
	.header-report * {
		text-align: center;
		padding: 0 !important;
		margin: 0 !important;
	}

	.header-custom * {
		text-align: center;
	}

	footer {
		text-align: right;
	}

	.header-report * {
		text-align: center;
		padding: 0 !important;
		margin: 0 !important;
	}

	body {
		font-size: 10pt;
	}

	h1 {
		font-size: 14pt;
		font-weight: bold;
		text-align: center;
	}

	header .header-report {
		border-bottom: 1px solid #999;
		padding-bottom: 5mm;
	}

	header .header-report img {
		height: 60px;
	}

	header .header-report p {
		text-transform: uppercase;
	}

	header .header-report .logo {
		padding-left: 5mm;
	}

	header .header-report h2 {
		font-size: 14pt;
	}


	header, section, footer {
		padding: 5mm;
	}

	table {
		width: 100%;
		border: 0;
	}

	footer {
		text-align: right;
	}
}

@media print {
	a:after { content:''; }
	a[href]:after { content: none !important; }

	.hide-on-print {
		display: none !important;
	}

	.show-on-print {
		display: inherit !important;
	}

	body, .container {
		width: 100%;
		min-width: 300px !important;
	}

	article {
		margin: 0;
		border: initial;
		border-radius: initial;
		width: initial;
		min-height: initial;
		box-shadow: initial;
		background: initial;
		page-break-after: always;
	}

	table td, table th {
		padding-top: 1mm !important;
		padding-bottom: 1mm !important;
	}
}

@media screen {
	.show-on-print {
		display: none;
	}

	article {
		border-bottom: 2px dashed #ddd;
	}

	.show-on-print {
		display: none;
	}
}

.logo.hide-on-400 {
	margin-left: auto !important;
}

body {
	font-size: 10pt;
}

h1 {
	font-size: 18pt;
}

header .header-report {
	border-bottom: 1px solid #999;
	padding-bottom: 5mm;
}

header .header-report img {
	height: 60px;
}

header .header-report p {
	text-transform: uppercase;
}

header .header-report .logo {
	padding-left: 5mm;
}

header .header-report h2 {
	font-size: 14pt;
}

header,
section,
footer {
	padding: 5mm 0 5m 5m;
}

table {
	width: 100%;
	border: 0;
}

.show-on-print {
	display: none;
}
</style>
